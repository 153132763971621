/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:26:03
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-05-29 16:12:28
 */
import request from '@/plugins/axios'
const base = '/'

export function importOrder(params) {
  return request({
    url:  base + 'api/jd/all/import',
    method: 'post',
    params
  })
}

export function addCookie(params) {
  return request({
    url:  base + 'api/jd/addCookie',
    method: 'get',
    params
  })
}